<template>

  <div>
    <h2
      class="mb-2"
    >
      Verified Emails
      <b-form-checkbox
        v-model="isVerifiedEmailsActive"
        class="ml-1"
        switch
        value="1"
        unchecked-value="0"
        inline
        @change="switchMemberVerificationStatus"
      />
    </h2>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
              :value="perPage"
              :reduce="option => option.value"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="6"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />

              <!-- Dropdown -->
              <b-dropdown
                variant="primary"
                toggle-class="p-0"
                no-caret
                right
                class="h-100 ml-1"
              >

                <template
                  #button-content
                >
                  <feather-icon
                    icon="GridIcon"
                    size="19"
                    class="m-50"
                  />
                </template>

                <b-dropdown-item
                  v-b-modal.error-message-modal
                >
                  <span>Error message</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="showAddEmailModal"
                >
                  <span>Add Email</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-b-modal.import-emails-modal
                >
                  <span>Import</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-b-modal.export-emails-modal
                >
                  <span>Export</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refVerifiedEmailsList"
        :items="fetchVerifiedEmails"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        sticky-header="75vh"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-sticky-head"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: Date -->
        <template #cell(updated_at)="data">
          <span>{{ data.value | longDate }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="showEditEmailModal(data.item)"
            >
              <span>Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteVerifiedEmail(data.item.id)"
            >
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

    <VerifiedEmailModal
      @refetchData="refetchData"
    />

    <ErrorMessageModal
      @refetchData="refetchData"
    />

    <ExportEmailsModal />

    <ImportEmailsModal
      @refetchData="refetchData"
    />
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable,
  BPagination, BDropdown, BDropdownItem, BFormCheckbox,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { filters } from '@core/mixins/filters'

import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import useVerifiedEmails from '@/views/admin/verified-emails/useVerifiedEmails'
import verifiedEmailsStoreModule from '@/views/admin/verified-emails/verifiedEmailsStoreModule'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import VerifiedEmailModal from '@/views/admin/verified-emails/modals/VerifiedEmailModal.vue'
import ErrorMessageModal from '@/views/admin/verified-emails/modals/ErrorMessageModal.vue'
import ExportEmailsModal from '@/views/admin/verified-emails/modals/ExportEmailsModal.vue'
import ImportEmailsModal from '@/views/admin/verified-emails/modals/ImportEmailsModal.vue'

import { setCurrentUserData } from '@/auth/utils'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  nmae: 'VerifiedEmailsList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,

    vSelect,

    TableSpinner,
    VerifiedEmailModal,
    ErrorMessageModal,
    ExportEmailsModal,
    ImportEmailsModal,
  },
  mixins: [filters],
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-verified-emails'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, verifiedEmailsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      isVerifiedEmailsActive,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refVerifiedEmailsList,
      fetchVerifiedEmails,
      postVerifiedEmails,
      updateVerifiedEmail,
      deleteVerifiedEmail,
      postMemberVerificationStatus,

      refetchData,
    } = useVerifiedEmails(root)

    return {
      tableColumns,
      isVerifiedEmailsActive,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refVerifiedEmailsList,
      fetchVerifiedEmails,
      postVerifiedEmails,
      updateVerifiedEmail,
      deleteVerifiedEmail,
      postMemberVerificationStatus,

      refetchData,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  mounted() {
    const schoolData = JSON.parse(localStorage.getItem('schoolData'))
    this.isVerifiedEmailsActive = schoolData.config.member_verification
  },
  methods: {
    async switchMemberVerificationStatus(status) {
      await this.postMemberVerificationStatus({ member_verification: status })
      await setCurrentUserData()
    },
    showAddEmailModal() {
      store.commit('app-verified-emails/SET_VERIFIED_EMAIL_MODAL_TYPE', 'add')
      this.$bvModal.show('verified-email-modal')
    },
    showEditEmailModal(item) {
      store.commit('app-verified-emails/SET_SELECTED_EMAIL', item)
      store.commit('app-verified-emails/SET_VERIFIED_EMAIL_MODAL_TYPE', 'edit')
      this.$bvModal.show('verified-email-modal')
    },
    updatePerPage(val) {
      localStorage.setItem('verifiedEmailsPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('verifiedEmailsPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
